.info-icon {
  font-size: 35px;
  margin-right: 4px;
}

.main-layout {
  display: flex;
  justify-content: center;
}

#blocklyDiv {
  height: 600px;
  width: 1024px;
}

.center {
  display: flex;
  justify-content: center;
}

.status-area {
  /*margin: 16px;*/
  width: 480px;
  align-self: stretch;
}

.status-area > div {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.buttons-bottom {
  margin-top: auto;
}

#code > div {
  margin: 16px 16px 16px 32px;
}

.custom-file-input {
  display: block;
  width: 50%;
  margin-top: 10px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.custom-file-input:hover {
  background-color: #0056b3;
}
