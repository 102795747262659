.level-progress-bar {
  display: flex;
  margin: 8px;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  align-content: center;
  font-weight: 600;
  font-size: larger;
}

.level {
  padding: 5px 16px;
  margin: 0px 4px;
  border-radius: 15%;
  font-weight: 600;
  font-size: larger;
  background: white;
  transition: transform 0.2s ease-in-out;
}
.current-level {
  color: goldenrod;
}
.level:hover {
  cursor: pointer;
  background: lightgray;
  transform: scale(1.1);
  font-weight: 700;
}
