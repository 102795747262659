iframe {
  pointer-events: none;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  pointer-events: none;
}

body {
  background-color: #cdebf9;
}
.dark-body {
  background-color: #282c34;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  padding-top: 16px;
  padding-bottom: 128px;
}

.dark-body .App-header {
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p > small {
  font-size: 12px;
}

.evaluation-button {
  padding-bottom: 10px;
}

.logo img {
  width: auto;
  height: 70px;
  margin-left: 10px;
}
