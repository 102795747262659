/* Modified version of: https://thecodeplayer.com/walkthrough/css3-family-tree */

* {
  margin: 0;
  padding: 0;
}

.tree {
  width: 100%;
  border: 1px solid #000;
  background-color: #fff;
  padding: 0 0% 100% 0%;
  height: 400px;
}

.tree ul {
  padding: 20px 0px;
  position: relative;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*We will use ::before and ::after to draw the connectors*/

.tree li::before,
.tree li::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 2px solid black;
  width: 50%;
  height: 20px;
}
.tree li::after {
  right: auto;
  left: 50%;
  border-left: 2px solid black;
}

/*We need to remove left-right connectors from elements without 
any siblings*/
.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}

/*Remove space from the top of single children*/
.tree li:only-child {
  padding-top: 0;
}

/*Remove left connector from first child and 
right connector from last child*/
.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}
/*Adding back the vertical connector to the last nodes*/
.tree li:last-child::before {
  border-right: 2px solid black;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}
.tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

/*Time to add downward connectors from parents*/
.tree ul ul::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid black;
  width: 0;
  height: 20px;
}

.node {
  border: 1px solid black;
  padding: 5px 10px;
  text-decoration: none;
  color: black;
  font-family: arial, verdana, tahoma;
  font-size: 16px;
  display: inline-block;

  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*Time for some hover effects*/
/*We will apply the hover effect the the lineage of the element also*/
.node:hover,
.node:hover + ul li a {
  background: #444;
  color: white;
  border: 1px solid orange;
}
/*Connector styles on hover*/
.node:hover + ul li::after,
.node:hover + ul li::before,
.node:hover + ul::before,
.node:hover + ul ul::before {
  border-color: darkgoldenrod;
}

/*Thats all. I hope you enjoyed it.
Thanks :)*/

/* src/DecisionTable.css */
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
  background-color: #ffffff;
}

th {
  background-color: #f2f2f2;
}

.highlight {
  background-color: #cceedd;
}
