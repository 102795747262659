.task-result {
  border: white solid;
  padding: 8px;
}

.task-result > h3 {
  margin: 4px 0px 2px 0px;
}

.task-result > p {
  margin: 4px 0px 4px 0px;
  font-size: 16px;
}

.speech-bubble {
  position: relative;
  padding: 1rem;
  border: 4px solid lightgrey;
  border-radius: 25px;
  background: #fff;
  margin: 0 auto 32px auto;
  height: 100%;
  width: 80%;
}

.speech-bubble-successful {
  border-color: green;
}

.speech-bubble::before,
.speech-bubble::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 100px;
  top: 100%;
  border: 20px solid;
}

.speech-bubble::before {
  border-color: lightgrey transparent transparent lightgrey;
}
.speech-bubble-successful::before {
  border-color: green transparent transparent green;
}

.speech-bubble::after {
  left: 104px;
  border-color: #ffffff transparent transparent #ffffff;
  border-width: 15px;
}

.turtle-speech-bubble {
  display: flex;
}
.modal-turtle-speech-bubble {
  display: flex;
  flex-direction: column;
}

.speech-bubble-vertical {
  position: relative;
  padding: 1rem;
  border: 3px solid lightgrey;
  border-radius: 25px;
  background: #fff;
  margin: 0 auto 32px 6px;
  height: 100%;
  min-height: 100px; /*#For Vertical*/
  max-width: 40%;
}

.speech-bubble-vertical-successful {
  border-color: green;
}

.speech-bubble-vertical::before,
.speech-bubble-vertical::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  right: 100%;
  top: 28px;
  border: 18px solid;
}

.speech-bubble-vertical::before {
  border-color: transparent lightgrey lightgrey transparent;
}
.speech-bubble-vertical-successful::before {
  border-color: transparent green green transparent;
}

.speech-bubble-vertical::after {
  right: 100%;
  top: 32px;
  border-color: transparent #ffffff #ffffff transparent;
  border-width: 15px;
}
