.main-div {
  margin-top: 8px;
}

.icon {
  vertical-align: middle;
  margin-right: 8px;
  align-content: center;
}

.back-button {
  position: "absolute";
  top: "16px";
}

.overview-card {
  height: 100%;
  cursor: pointer;
}

.overview-card button {
  position: absolute;
  bottom: 0;
}

.container {
  margin-top: 20px;
  padding-bottom: 10px;
}

.welcome-description {
  text-align: center;
  font-size: x-large;
  padding-bottom: 20px;
  font-weight: 500;
}

.card-text-hidden {
  text-align: left;
}

.card-border {
  transition: border-color 0.3s ease-in-out;
  border: 4px solid transparent;
}

.card-border:hover {
  border-image: linear-gradient(to bottom, rgb(255, 196, 0), orange) 1;
}

.settings-buttons {
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: end;
  justify-content: center;
  height: 450px;
}

.imprint-button a {
  color: white;
  text-decoration: none; /* Remove underline */
}
