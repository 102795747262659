#blocklyDiv {
    height: 600px;
    width: 1024px;
    width: 700px;
}

@media (min-width: 1300px) {
    #blocklyDiv {
        width: 1024px;
    }
}

.Blockly-Main {
    display: flex;
    margin: 8px;
}

.Blockly-Editor {
    margin: 8px;
}

.Blockly-View {
    margin: 8px;
    min-width: 370px;
}

.grid-world {
    margin-bottom: 8px;
}

.reward-per-epoch-wrapper {
    margin-bottom: 8px;
    background-color: white;
}

.btn {
    margin-bottom: 8px;
}

.Task{
    font-weight: bold;
    width: 91%;
    margin-bottom: 0px;
}
